import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }


  /* Remove Empty String From Obj */
  removeEmptyStrings(obj) {
    const dataObj = { ...obj };
    Object.entries(dataObj).forEach(([key, val]) => val === '' && delete dataObj[key]);
    return dataObj;
  }
  /* Remove Empty Object from array */
  removeEmptyObj(arr) {
    return arr.filter(value => Object.keys(value).length !== 0);
  }


}
